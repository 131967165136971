@import "./scss/custom";

p {
  text-align: justify;
}

.App {
  text-align: center;
}

.section {
  text-align: center;
}

// .App-logo {
//   height: 20vmin;
//   pointer-events: none;
// }

/* body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
} */

/* nav {
  box-shadow:2px 2px 2px 2px black;
} */

nav a {
  text-decoration: none;
  color: $light;
  font-weight: bold;
  padding: 16px 32px;
}

nav a:hover {
  background: $light;
  color: $dark;
}

section {
  padding-top: 32px;
  min-height: 400px;
  // padding-bottom: 32px;
  margin: auto;
}

section::after {
  content: "";
  /* This is necessary for the pseudo element to work. */
  display: block;
  /* This will put the pseudo element on its own line. */
  margin: 0 auto;
  /* This will center the border. */
  width: 90%;
  /* Change this to whatever width you want. */
  padding-top: 20px;
  /* This creates some space between the element and the border. */
  border-bottom: 2px solid black;
  /* This creates the border. Replace black with whatever color you want. */
}

#home-section {
  padding-top: 0px;
  // min-height: 600px;
  margin: 0;
}

#home-section::after {
  content: "";
  border-bottom: none;
}

.about-row {
  margin: -20% 0% 0% 0%;
  // padding-bottom: 40px;
}

.about-profilepic {
  // margin: -15% 0% 0% 0%;
  // padding-bottom: 40px;
  max-Height: 250px;
  max-Width: 250px;
  background-color: transparent;
}

.about-banner {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.about-textbox {
  align-self: center;
  
  // color:white; 
  // background-color: );
  // background-image: linear-gradient(to right,rgba(75, 75, 75, 1), rgba(0, 0, 0, .98));
  text-shadow: 1px 1px 2px rgb(213, 213, 213);
  color:rgb(0, 0, 0); 
  background-image: linear-gradient(to right,rgb(255, 255, 255), rgba(210, 210, 210, 0.95));
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);

  padding: 20px;
  border-radius: 50px;
  // height: 200px;
  // border: 1px solid #ffffff;
}

@media screen and (max-width: 420px) {

  #home-section::after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    margin: 0 auto;
    /* This will center the border. */
    width: 90%;
    /* Change this to whatever width you want. */
    padding-top: 20px;
    /* This creates some space between the element and the border. */
    border-bottom: 2px solid black;
    /* This creates the border. Replace black with whatever color you want. */
  }
  

  .about-banner {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .about-profilepic {
    // margin: -15% 0% 0% 0%;
    // padding-bottom: 40px;
    max-Height: 200px;
    max-Width: 200px;
    background-color: transparent;
  }

  .about-row {
    margin: -10% 0% 0% 0%;
    padding-bottom: 0px;
  }

  .about-textbox {
    text-shadow: none;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    color:black; 
    background-color: white;
    background-image: none;
    padding: 20px;
  }
}

// #portfolio-section {
//   padding-top: 0px;
//   // min-height: 600px;
//   // padding-bottom: 32px;
//   margin: none;
// }

#game-section {
  padding-top: 32px;
  min-height: 600px;
  // padding-bottom: 32px;
  margin: none;
}

#skills-section::after {
  content: "";
  /* This is necessary for the pseudo element to work. */
  display: block;
  /* This will put the pseudo element on its own line. */
  margin: 0 auto;
  /* Change this to whatever width you want. */
  padding-top: 20px;
  /* This creates the border. Replace black with whatever color you want. */
  border-bottom: none;
}

#contact-section {
  background-color: black;
  color: white;

  align-items: center;
  align-self: center;
  align-content: center;
  // display: inline;
}

.card-title-skills {
  background-color: $dark;
  color: white;

  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  text-align: center;
}

.card-subtitle {
  padding-bottom: 1rem;
  // padding: $card-title-padding-bottom;
}

.card-custom {
  min-width: 10rem;
  max-width: 25rem;
  margin: 1rem;

  position: relative;
  display: flex;
  flex-direction: column;
  // min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
  @include box-shadow($card-box-shadow);

  >hr {
    margin-right: 0;
    margin-left: 0;
  }
}

.game-headerimg {
  min-height: 800px;
  ;
  background-image: url("images/vrtbt_title_16x9.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.game-header-img {

  margin: 0px;
  // padding:0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // object-fit: fill;
}

.game-genre-img {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  // object-fit: cover;
}

.game-overview-key-info {
  // padding:5px;
  text-align: left;

}

.game-overview-key-info h6 {
  margin: 0px 0px 30px 0px;
}

.zoom {
  // padding: 50px;
  transition: transform .2s;
  /* Animation */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;

  border-radius: 20px;
  // box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.zoom:hover {
  transform: scale(1.2);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.game-background {
  background-color: $blue-700;
  // background-color: $blue-800;
  // background-image: var(--bs-gradient);
  color: $gray-100;
}

.game-banner {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100px;
  opacity: 90%;
  // object-fit:cover;
  // position: absolute;
  // padding: 0;
  // display: block;
  // margin: 0 auto;
  // max-height: 50px;
}

.game-banner-video-container
{
  margin: 0;
  padding: 0;
}

.game-banner-video {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 420px) {

  .game-banner-video {
    height: 600px;
    width: 100%;
    object-fit: cover;
  }
}


.game-banner-container {
  position: relative;
  width: 100%;
}

.game-banner-background {
  margin: 10% 0 0 0;
  padding: 0%;
  position: absolute;
  width: 100%;
  height: 15%;
  // opacity: 90%;
  // background-color: $blue-900;
  background-color: $yellow-400;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
}

.game-banner-text {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  color: white;
  font-size: 100px;
}

@media screen and (max-width: 420px) {
  .game-banner-container {
    position: relative;
    height: 600px;
    width: 100%;
  }
  
  .game-banner-background {
    margin: 20% 0 0 0;
    position: absolute;
    height: 15%;
  }
  
  .game-banner-text {
  font-size: 40px;
  }
}

.game-summary {
  background-color: $gray-900;
  background-image: var(--bs-gradient);
  border-top: 1rem solid $gray-800;
  // border-bottom:1rem  solid $gray-900;
  margin: 2rem 10rem;
  padding: 1rem;
}

@media screen and (max-width: 420px) {
  .game-summary {
    margin: 2rem 2rem;
  }
}

.game-overview {
  background-image: linear-gradient(to right, $blue-700 ,$blue-300);
  background-color: $gray-100;
  // background-image: var(--bs-gradient);
  // color: $gray-800;
  color: white;
  // border-top: 1rem  solid $white-800;
  // border-bottom:1rem  solid $gray-900;
    border-left:10rem  solid $blue-700;
    border-right:10rem  solid $blue-700;
  margin: 5rem 0rem;
}

@media screen and (max-width: 420px) {
  .game-overview {
    margin: 2rem 2rem;

    border-left:1rem  solid $blue-700;
    border-right:1rem  solid $blue-700;
    margin: 1rem 0rem;
  }
}

.game-overview-container {
  margin: 5rem 10rem;
  padding: 1rem;
}

.game-row {
  align-items: center;
  width: 100%;
  margin: auto;
  padding: 20px;
}

.game-summary li { 
  list-style-type: square;
  text-align: left;
  margin: 1rem 0rem;
}

.game-screenshots-background {
  background-color: $yellow-400;
  // background-color: $gray-200;
  background-image: var(--bs-gradient);
  border-top: 1rem solid $yellow-400;
}

.game-screenshots-container {
  width: 100%;
  margin: auto;
  padding: 3rem;
  //     margin: 0;
  // padding: 0;
}

// .game-trailer-background
// {
//   background-color: $blue-700;
//   background-image: var(--bs-gradient);
//   border-top: 5px $blue-900;
//   border-bottom:5px $blue-900;
// }


.game-trailer {
  // margin: 3rem;
  margin: 0rem 0rem 4rem 0rem;
  padding: 3rem;
}


// .yt {
//     margin: 10%;
// }

@media screen and (max-width: 420px) {
  .yt {
    position: relative;
    display: block;
    // width: 50%; /* width of iframe wrapper */
    // padding: 0% 0% 28.13%; /* 16:9 ratio */
    height: 0;
    margin: auto;
    // margin: 100px 0px;
    width: 90%;
    /* width of iframe wrapper */
    padding: 0% 0% 56.25%;
    /* 16:9 ratio */
    overflow: hidden;
  }

  .yt iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
  }
}


.game-footerlinks {
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: $blue-900;
}

// .btn-primary {
//   color: #fff;
//   background-color: green;
//   border-color: green;
// }

// .custom-form {

//   // background-color: black;
//   max-width: 800px;
//   align-self: center;
//   // .custom-button {
//   //   background-color: white;
//   // }
// }